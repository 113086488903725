import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InfoBannerComponent } from "./info-banner.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [InfoBannerComponent],
  exports: [InfoBannerComponent],
})
export class InfoBannerComponentModule {}
