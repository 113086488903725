<div class="generate-api-key modal">
    <h1 class="modal__header" [innerHTML]="'COMPONENTS.GENERATE_API_KEY.title' | translate"></h1>
    <div class="generate-api-key__content modal__content">
        <div class="generate-api-key__content__container">
            <app-form-field-wrapper [size]="100">
                <mat-form-field [formGroup]="form">
                    <mat-label [innerHTML]="'COMPONENTS.GENERATE_API_KEY.apiKey' | translate"></mat-label>
                    <input formControlName="data" matInput />
                    <mat-icon class="--is-clickable" matSuffix (click)="utilsService.copyToClipboard(form.controls['data'].value)">
                        copy_all
                    </mat-icon>
                </mat-form-field>
            </app-form-field-wrapper>

            <div class="generate-api-key__content__container__toast" id="toastContainer"></div>
        </div>

        <span class="generate-api-key__content__text --paragraph" [innerHTML]="'COMPONENTS.GENERATE_API_KEY.alertText' | translate"></span>
    </div>
    <div class="modal__footer">
        <div class="modal__footer__buttons">
            <button class="generate-api-key__actions__button" color="transparent" mat-flat-button (click)="dialogRef.close()">
                {{ "COMPONENTS.GENERATE_API_KEY.close" | translate }}
            </button>
        </div>
    </div>
</div>
