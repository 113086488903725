import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AddLinkComponent } from "./add-link.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormFieldWrapperComponentModule } from "../../components/form-field-wrapper/form-field-wrapper.module";
import { InputVariablesComponentModule } from "../../components/input-variables/input-variables.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FormFieldWrapperComponentModule,
    InputVariablesComponentModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
  declarations: [AddLinkComponent],
  exports: [AddLinkComponent],
})
export class AddLinkComponentModule {}
