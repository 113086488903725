import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, combineLatest, map } from "rxjs";
import { RootState, fromProgram } from "../../store";
import { AppRoutes } from "../../enums";
import { Program } from "../../services/api";

@Injectable({ providedIn: "root" })
export class OnboardingCompleteGuard implements CanActivate {
  public readonly program$: Observable<Program> = this.store.select(fromProgram.selectProgram);
  public readonly isUsersFeatureEnabled$: Observable<boolean> = this.store.select(fromProgram.selectIsFeatureEnabledByName("users"));
  public readonly isLoyaltyFeatureEnabled$: Observable<boolean> = this.store.select(fromProgram.selectIsFeatureEnabledByName("loyalty"));

  constructor(
    private readonly store: Store<RootState>,
    private readonly router: Router,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([this.program$, this.isUsersFeatureEnabled$, this.isLoyaltyFeatureEnabled$]).pipe(
      map(([program, isUsersFeatureEnabled, isLoyaltyFeatureEnabled]) => {
        if (!!program && isUsersFeatureEnabled && isLoyaltyFeatureEnabled) return true;
        return this.router.createUrlTree([AppRoutes.root, AppRoutes.onboarding]);
      }),
    );
  }
}
