/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, first, map, mergeMap, tap, withLatestFrom } from "rxjs";
import { Store } from "@ngrx/store";
import { RootState, appActions, fromApp, fromRouter } from "..";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeDe from "@angular/common/locales/de";
import { DOCUMENT, registerLocaleData } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { AppRouteParams, AppRoutes, Languages } from "../../enums";
import { Router } from "@angular/router";

@Injectable()
export class AppEffects {
  public changeLanguage$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.changeLanguage),
      withLatestFrom(this.store.select(fromApp.selectLanguage)),
      mergeMap(([action, language]) => {
        const shouldReload = language !== action.language;

        switch (action.language) {
          case Languages.en:
            registerLocaleData(localeEn);
            break;
          case Languages.de:
            registerLocaleData(localeDe);
            break;
          case Languages.es:
            registerLocaleData(localeEs);
            break;
        }

        this.translateService.use(action.language);

        if (shouldReload) this.document.location.reload();

        return [appActions.changeLanguageSuccess({ language: action.language })];
      }),
    ),
  );

  public logOut$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.logOut),
      withLatestFrom(this.store.select(fromRouter.selectRouteParam(AppRouteParams.programSlug))),
      first(([, slug]) => !!slug),
      tap(([, slug]) => {
        this.router.navigate([AppRoutes.root, slug, AppRoutes.login]);
        if (window["posthog"]) window["posthog"].reset();
      }),
      map(() => appActions.logOutSuccess()),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}
}
