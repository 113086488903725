import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfirmComponent } from "./confirm.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  imports: [CommonModule, TranslateModule, MatButtonModule, MatDialogModule],
  declarations: [ConfirmComponent],
  exports: [ConfirmComponent],
})
export class ConfirmComponentModule {}
