import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SendPushComponent } from "./send-push.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormFieldWrapperComponentModule } from "../../components/form-field-wrapper/form-field-wrapper.module";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { InfoBannerComponentModule } from "../../components/info-banner/info-banner.module";
import { InputVariablesComponentModule } from "../../components/input-variables/input-variables.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FormFieldWrapperComponentModule,
    MatProgressSpinnerModule,
    InfoBannerComponentModule,
    InputVariablesComponentModule,
  ],
  declarations: [SendPushComponent],
  exports: [SendPushComponent],
})
export class SendPushComponentModule {}
