<div class="understood modal">
    <h1 class="modal__header understood__title --m" [innerHTML]="data.title | translate"></h1>
    <app-info-banner class="modal__content understood__text" status="info" [hideClose]="true">
        {{ data.description | translate }}
    </app-info-banner>
    <div class="modal__footer">
        <div class="modal__footer__buttons">
            <button mat-flat-button [color]="data.buttonColor" (click)="understood()">
                {{ "UNDERSTOOD_PAGE.button" | translate }}
            </button>
        </div>
    </div>
</div>
