<div class="update-integration-confirm modal">
    <h1 class="modal__header" [innerHTML]="'UPDATE_INTEGRATION_CONFIRM.title' | translate"></h1>

    <form class="modal__content update-integration-confirm__content form" [formGroup]="form">
        <app-info-banner status="info" [hideClose]="true">
            {{ data.description | translate }}
        </app-info-banner>

        <app-form-field-wrapper [size]="100">
            <mat-form-field>
                <mat-label [innerHTML]="'UPDATE_INTEGRATION_CONFIRM.input' | translate"></mat-label>
                <input formControlName="input" matInput [placeholder]="'UPDATE_INTEGRATION_CONFIRM.inputPlaceholder' | translate" />
            </mat-form-field>
        </app-form-field-wrapper>
    </form>

    <div class="modal__footer">
        <div class="modal__footer__buttons">
            <button color="transparent" mat-flat-button (click)="confirm(false)">
                {{ "UPDATE_INTEGRATION_CONFIRM.cancel" | translate }}
            </button>
            <button color="danger" mat-flat-button [disabled]="form.invalid" (click)="confirm(true)">
                {{ "UPDATE_INTEGRATION_CONFIRM.confirm" | translate }}
            </button>
        </div>
    </div>
</div>
