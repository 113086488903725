/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1238
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CouponBatchDto } from './coupon-batch-dto';


export interface CouponDto { 
    id: string;
    programId: string;
    name: string;
    slug: string;
    status: CouponDto.StatusEnum;
    description?: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    totalCodes: number;
    claimedCodes: number;
    batches: Array<CouponBatchDto>;
}
export namespace CouponDto {
    export type StatusEnum = 'available' | 'not_available' | 'deleted';
    export const StatusEnum = {
        Available: 'available' as StatusEnum,
        NotAvailable: 'not_available' as StatusEnum,
        Deleted: 'deleted' as StatusEnum
    };
}


