/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, catchError, combineLatest, first, switchMap, throwError } from "rxjs";
import { RootState, fromApp } from "../../store";
import { Program } from "../../services/api";

@Injectable({ providedIn: "root" })
export class ConvercusInterceptor implements HttpInterceptor {
  private readonly accessToken$: Observable<string> = this.store.select(fromApp.selectAccessToken);
  private readonly iframeIntegrationName$: Observable<string> = this.store.select(fromApp.selectIframeIntegrationName);

  constructor(private readonly store: Store<RootState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.accessToken$, this.iframeIntegrationName$]).pipe(
      first(),
      switchMap(([accessToken, iframeIntegrationName]) => {
        if (iframeIntegrationName !== Program.IntegrationEnum.Convercus) return next.handle(req);

        req = req.clone({ setHeaders: { authorization: `Bearer ${accessToken}` } });

        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status >= HttpStatusCode.InternalServerError) console.error(error);
            return throwError(() => error.error);
          }),
        );
      }),
    );
  }
}
