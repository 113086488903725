/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1238
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SubscriptionInfo {
    status: SubscriptionInfo.StatusEnum;
    freePasses: number;
    installedPasses: number;
    usageCharges: number;
    cappedAmount: number;
}
export namespace SubscriptionInfo {
    export type StatusEnum = '-1' | '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const StatusEnum = {
        1: '-1' as StatusEnum,
        _0: '0' as StatusEnum,
        _1: '1' as StatusEnum,
        _2: '2' as StatusEnum,
        _3: '3' as StatusEnum,
        _4: '4' as StatusEnum,
        _5: '5' as StatusEnum,
        _6: '6' as StatusEnum
    };
}


