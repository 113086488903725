/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1238
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AccessToken } from '../model/models';
import { ApiKey } from '../model/models';
import { CampaignAnalytics } from '../model/models';
import { CampaignAssignation } from '../model/models';
import { CampaignCustomerDto } from '../model/models';
import { CampaignDto } from '../model/models';
import { CheckProgram } from '../model/models';
import { CreateApiKey } from '../model/models';
import { CreateBooking } from '../model/models';
import { CreateCampaign } from '../model/models';
import { CreateCustomerEvent } from '../model/models';
import { CreateIntegration } from '../model/models';
import { CreateLink } from '../model/models';
import { CreateLocation } from '../model/models';
import { CreateProgram } from '../model/models';
import { CustomerData } from '../model/models';
import { CustomerEventDto } from '../model/models';
import { Feature } from '../model/models';
import { Integration } from '../model/models';
import { Link } from '../model/models';
import { Location } from '../model/models';
import { NameDto } from '../model/models';
import { PaginatedCustomerEvents } from '../model/models';
import { PaginatedCustomers } from '../model/models';
import { Program } from '../model/models';
import { ProgramIntegration } from '../model/models';
import { RedirectLink } from '../model/models';
import { SegmentDto } from '../model/models';
import { SlugDto } from '../model/models';
import { SubscriptionInfo } from '../model/models';
import { UpdateCampaign } from '../model/models';
import { UpdateIntegration } from '../model/models';
import { UpdateLink } from '../model/models';
import { UpdateLocation } from '../model/models';
import { UpdateProgram } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    AdminServiceInterface,
    AdminControllerAddIntegrationRequestParams,
    AdminControllerAddLinkRequestParams,
    AdminControllerAddLocationRequestParams,
    AdminControllerAddPointsToCustomerRequestParams,
    AdminControllerAssignTriggeredCampaignRequestParams,
    AdminControllerCheckCampaignSlugRequestParams,
    AdminControllerCheckProgramSlugExistsRequestParams,
    AdminControllerCreateCampaignRequestParams,
    AdminControllerCreateCustomerEventRequestParams,
    AdminControllerCreateProgramRequestParams,
    AdminControllerCreateProgramSubscriptionRequestParams,
    AdminControllerDeleteApiKeyRequestParams,
    AdminControllerDeleteCampaignRequestParams,
    AdminControllerDeleteLinkRequestParams,
    AdminControllerDeleteLocationRequestParams,
    AdminControllerExchangeExternalCustomerIdToCustomerIdRequestParams,
    AdminControllerGenerateApiKeyRequestParams,
    AdminControllerGetCampaignAnalyticsRequestParams,
    AdminControllerGetCampaignByIdRequestParams,
    AdminControllerGetCustomerByIdRequestParams,
    AdminControllerGetCustomerEventsRequestParams,
    AdminControllerGetCustomerTokenRequestParams,
    AdminControllerGetProgramApiKeysRequestParams,
    AdminControllerGetProgramCampaignsRequestParams,
    AdminControllerGetProgramCustomersRequestParams,
    AdminControllerGetProgramLinksRequestParams,
    AdminControllerGetProgramLocationsRequestParams,
    AdminControllerGetProgramSubscriptionInfoRequestParams,
    AdminControllerGetSegmentsRequestParams,
    AdminControllerPatchProgramLinksRequestParams,
    AdminControllerReactivateCampaignRequestParams,
    AdminControllerRemovePointsToCustomerRequestParams,
    AdminControllerUpdateCampaignRequestParams,
    AdminControllerUpdateLinkRequestParams,
    AdminControllerUpdateLocationRequestParams,
    AdminControllerUpdateProgramRequestParams,
    AdminControllerUpdateProgramIntegrationRequestParams
} from './admin.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class AdminService implements AdminServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerAddIntegration(requestParameters: AdminControllerAddIntegrationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerAddIntegration(requestParameters: AdminControllerAddIntegrationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerAddIntegration(requestParameters: AdminControllerAddIntegrationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerAddIntegration(requestParameters: AdminControllerAddIntegrationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const createIntegration = requestParameters.createIntegration;
        if (createIntegration === null || createIntegration === undefined) {
            throw new Error('Required parameter createIntegration was null or undefined when calling adminControllerAddIntegration.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerAddIntegration.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/integrations`,
            createIntegration,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerAddLink(requestParameters: AdminControllerAddLinkRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerAddLink(requestParameters: AdminControllerAddLinkRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerAddLink(requestParameters: AdminControllerAddLinkRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerAddLink(requestParameters: AdminControllerAddLinkRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const createLink = requestParameters.createLink;
        if (createLink === null || createLink === undefined) {
            throw new Error('Required parameter createLink was null or undefined when calling adminControllerAddLink.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerAddLink.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/links`,
            createLink,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerAddLocation(requestParameters: AdminControllerAddLocationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerAddLocation(requestParameters: AdminControllerAddLocationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerAddLocation(requestParameters: AdminControllerAddLocationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerAddLocation(requestParameters: AdminControllerAddLocationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const createLocation = requestParameters.createLocation;
        if (createLocation === null || createLocation === undefined) {
            throw new Error('Required parameter createLocation was null or undefined when calling adminControllerAddLocation.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerAddLocation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/locations`,
            createLocation,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerAddPointsToCustomer(requestParameters: AdminControllerAddPointsToCustomerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CustomerData>;
    public adminControllerAddPointsToCustomer(requestParameters: AdminControllerAddPointsToCustomerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CustomerData>>;
    public adminControllerAddPointsToCustomer(requestParameters: AdminControllerAddPointsToCustomerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CustomerData>>;
    public adminControllerAddPointsToCustomer(requestParameters: AdminControllerAddPointsToCustomerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createBooking = requestParameters.createBooking;
        if (createBooking === null || createBooking === undefined) {
            throw new Error('Required parameter createBooking was null or undefined when calling adminControllerAddPointsToCustomer.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerAddPointsToCustomer.');
        }
        const customerId = requestParameters.customerId;
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling adminControllerAddPointsToCustomer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<CustomerData>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/customers/${encodeURIComponent(String(customerId))}/add-balance`,
            createBooking,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerAssignTriggeredCampaign(requestParameters: AdminControllerAssignTriggeredCampaignRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CampaignCustomerDto>;
    public adminControllerAssignTriggeredCampaign(requestParameters: AdminControllerAssignTriggeredCampaignRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CampaignCustomerDto>>;
    public adminControllerAssignTriggeredCampaign(requestParameters: AdminControllerAssignTriggeredCampaignRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CampaignCustomerDto>>;
    public adminControllerAssignTriggeredCampaign(requestParameters: AdminControllerAssignTriggeredCampaignRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const campaignAssignation = requestParameters.campaignAssignation;
        if (campaignAssignation === null || campaignAssignation === undefined) {
            throw new Error('Required parameter campaignAssignation was null or undefined when calling adminControllerAssignTriggeredCampaign.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerAssignTriggeredCampaign.');
        }
        const slug = requestParameters.slug;
        if (slug === null || slug === undefined) {
            throw new Error('Required parameter slug was null or undefined when calling adminControllerAssignTriggeredCampaign.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<CampaignCustomerDto>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns/${encodeURIComponent(String(slug))}/assignation`,
            campaignAssignation,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerCheckCampaignSlug(requestParameters: AdminControllerCheckCampaignSlugRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SlugDto>;
    public adminControllerCheckCampaignSlug(requestParameters: AdminControllerCheckCampaignSlugRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SlugDto>>;
    public adminControllerCheckCampaignSlug(requestParameters: AdminControllerCheckCampaignSlugRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SlugDto>>;
    public adminControllerCheckCampaignSlug(requestParameters: AdminControllerCheckCampaignSlugRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const nameDto = requestParameters.nameDto;
        if (nameDto === null || nameDto === undefined) {
            throw new Error('Required parameter nameDto was null or undefined when calling adminControllerCheckCampaignSlug.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerCheckCampaignSlug.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SlugDto>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns/check`,
            nameDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerCheckProgramSlugExists(requestParameters: AdminControllerCheckProgramSlugExistsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerCheckProgramSlugExists(requestParameters: AdminControllerCheckProgramSlugExistsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerCheckProgramSlugExists(requestParameters: AdminControllerCheckProgramSlugExistsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerCheckProgramSlugExists(requestParameters: AdminControllerCheckProgramSlugExistsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const checkProgram = requestParameters.checkProgram;
        if (checkProgram === null || checkProgram === undefined) {
            throw new Error('Required parameter checkProgram was null or undefined when calling adminControllerCheckProgramSlugExists.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/program/check`,
            checkProgram,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerCreateCampaign(requestParameters: AdminControllerCreateCampaignRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CampaignDto>;
    public adminControllerCreateCampaign(requestParameters: AdminControllerCreateCampaignRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CampaignDto>>;
    public adminControllerCreateCampaign(requestParameters: AdminControllerCreateCampaignRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CampaignDto>>;
    public adminControllerCreateCampaign(requestParameters: AdminControllerCreateCampaignRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createCampaign = requestParameters.createCampaign;
        if (createCampaign === null || createCampaign === undefined) {
            throw new Error('Required parameter createCampaign was null or undefined when calling adminControllerCreateCampaign.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerCreateCampaign.');
        }
        const xAPPTIMEZONE = requestParameters.xAPPTIMEZONE;

        let headers = this.defaultHeaders;
        if (xAPPTIMEZONE !== undefined && xAPPTIMEZONE !== null) {
            headers = headers.set('X-APP-TIMEZONE', String(xAPPTIMEZONE));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<CampaignDto>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns`,
            createCampaign,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerCreateCustomerEvent(requestParameters: AdminControllerCreateCustomerEventRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CustomerEventDto>;
    public adminControllerCreateCustomerEvent(requestParameters: AdminControllerCreateCustomerEventRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CustomerEventDto>>;
    public adminControllerCreateCustomerEvent(requestParameters: AdminControllerCreateCustomerEventRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CustomerEventDto>>;
    public adminControllerCreateCustomerEvent(requestParameters: AdminControllerCreateCustomerEventRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createCustomerEvent = requestParameters.createCustomerEvent;
        if (createCustomerEvent === null || createCustomerEvent === undefined) {
            throw new Error('Required parameter createCustomerEvent was null or undefined when calling adminControllerCreateCustomerEvent.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerCreateCustomerEvent.');
        }
        const customerId = requestParameters.customerId;
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling adminControllerCreateCustomerEvent.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<CustomerEventDto>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/customers/${encodeURIComponent(String(customerId))}/events`,
            createCustomerEvent,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerCreateProgram(requestParameters: AdminControllerCreateProgramRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Program>;
    public adminControllerCreateProgram(requestParameters: AdminControllerCreateProgramRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Program>>;
    public adminControllerCreateProgram(requestParameters: AdminControllerCreateProgramRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Program>>;
    public adminControllerCreateProgram(requestParameters: AdminControllerCreateProgramRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createProgram = requestParameters.createProgram;
        if (createProgram === null || createProgram === undefined) {
            throw new Error('Required parameter createProgram was null or undefined when calling adminControllerCreateProgram.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Program>(`${this.configuration.basePath}/v1/program`,
            createProgram,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerCreateProgramSubscription(requestParameters: AdminControllerCreateProgramSubscriptionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<RedirectLink>;
    public adminControllerCreateProgramSubscription(requestParameters: AdminControllerCreateProgramSubscriptionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<RedirectLink>>;
    public adminControllerCreateProgramSubscription(requestParameters: AdminControllerCreateProgramSubscriptionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<RedirectLink>>;
    public adminControllerCreateProgramSubscription(requestParameters: AdminControllerCreateProgramSubscriptionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerCreateProgramSubscription.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<RedirectLink>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/subscriptions`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerDeleteApiKey(requestParameters: AdminControllerDeleteApiKeyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerDeleteApiKey(requestParameters: AdminControllerDeleteApiKeyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerDeleteApiKey(requestParameters: AdminControllerDeleteApiKeyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerDeleteApiKey(requestParameters: AdminControllerDeleteApiKeyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerDeleteApiKey.');
        }
        const apiKeyId = requestParameters.apiKeyId;
        if (apiKeyId === null || apiKeyId === undefined) {
            throw new Error('Required parameter apiKeyId was null or undefined when calling adminControllerDeleteApiKey.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/api-keys/${encodeURIComponent(String(apiKeyId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerDeleteCampaign(requestParameters: AdminControllerDeleteCampaignRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerDeleteCampaign(requestParameters: AdminControllerDeleteCampaignRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerDeleteCampaign(requestParameters: AdminControllerDeleteCampaignRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerDeleteCampaign(requestParameters: AdminControllerDeleteCampaignRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerDeleteCampaign.');
        }
        const campaignId = requestParameters.campaignId;
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling adminControllerDeleteCampaign.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns/${encodeURIComponent(String(campaignId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerDeleteLink(requestParameters: AdminControllerDeleteLinkRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerDeleteLink(requestParameters: AdminControllerDeleteLinkRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerDeleteLink(requestParameters: AdminControllerDeleteLinkRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerDeleteLink(requestParameters: AdminControllerDeleteLinkRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerDeleteLink.');
        }
        const linkId = requestParameters.linkId;
        if (linkId === null || linkId === undefined) {
            throw new Error('Required parameter linkId was null or undefined when calling adminControllerDeleteLink.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/links/${encodeURIComponent(String(linkId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerDeleteLocation(requestParameters: AdminControllerDeleteLocationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerDeleteLocation(requestParameters: AdminControllerDeleteLocationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerDeleteLocation(requestParameters: AdminControllerDeleteLocationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerDeleteLocation(requestParameters: AdminControllerDeleteLocationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerDeleteLocation.');
        }
        const locationId = requestParameters.locationId;
        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling adminControllerDeleteLocation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/locations/${encodeURIComponent(String(locationId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerExchangeExternalCustomerIdToCustomerId(requestParameters: AdminControllerExchangeExternalCustomerIdToCustomerIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<string>;
    public adminControllerExchangeExternalCustomerIdToCustomerId(requestParameters: AdminControllerExchangeExternalCustomerIdToCustomerIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<string>>;
    public adminControllerExchangeExternalCustomerIdToCustomerId(requestParameters: AdminControllerExchangeExternalCustomerIdToCustomerIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<string>>;
    public adminControllerExchangeExternalCustomerIdToCustomerId(requestParameters: AdminControllerExchangeExternalCustomerIdToCustomerIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerExchangeExternalCustomerIdToCustomerId.');
        }
        const externalCustomerId = requestParameters.externalCustomerId;
        if (externalCustomerId === null || externalCustomerId === undefined) {
            throw new Error('Required parameter externalCustomerId was null or undefined when calling adminControllerExchangeExternalCustomerIdToCustomerId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<string>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/customers/${encodeURIComponent(String(externalCustomerId))}/exchange`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGenerateApiKey(requestParameters: AdminControllerGenerateApiKeyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerGenerateApiKey(requestParameters: AdminControllerGenerateApiKeyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerGenerateApiKey(requestParameters: AdminControllerGenerateApiKeyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerGenerateApiKey(requestParameters: AdminControllerGenerateApiKeyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const createApiKey = requestParameters.createApiKey;
        if (createApiKey === null || createApiKey === undefined) {
            throw new Error('Required parameter createApiKey was null or undefined when calling adminControllerGenerateApiKey.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGenerateApiKey.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/api-keys`,
            createApiKey,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetCampaignAnalytics(requestParameters: AdminControllerGetCampaignAnalyticsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CampaignAnalytics>;
    public adminControllerGetCampaignAnalytics(requestParameters: AdminControllerGetCampaignAnalyticsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CampaignAnalytics>>;
    public adminControllerGetCampaignAnalytics(requestParameters: AdminControllerGetCampaignAnalyticsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CampaignAnalytics>>;
    public adminControllerGetCampaignAnalytics(requestParameters: AdminControllerGetCampaignAnalyticsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetCampaignAnalytics.');
        }
        const campaignId = requestParameters.campaignId;
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling adminControllerGetCampaignAnalytics.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<CampaignAnalytics>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns/${encodeURIComponent(String(campaignId))}/analytics`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetCampaignById(requestParameters: AdminControllerGetCampaignByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CampaignDto>;
    public adminControllerGetCampaignById(requestParameters: AdminControllerGetCampaignByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CampaignDto>>;
    public adminControllerGetCampaignById(requestParameters: AdminControllerGetCampaignByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CampaignDto>>;
    public adminControllerGetCampaignById(requestParameters: AdminControllerGetCampaignByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetCampaignById.');
        }
        const campaignId = requestParameters.campaignId;
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling adminControllerGetCampaignById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<CampaignDto>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns/${encodeURIComponent(String(campaignId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetCustomerById(requestParameters: AdminControllerGetCustomerByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CustomerData>;
    public adminControllerGetCustomerById(requestParameters: AdminControllerGetCustomerByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CustomerData>>;
    public adminControllerGetCustomerById(requestParameters: AdminControllerGetCustomerByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CustomerData>>;
    public adminControllerGetCustomerById(requestParameters: AdminControllerGetCustomerByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetCustomerById.');
        }
        const customerId = requestParameters.customerId;
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling adminControllerGetCustomerById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<CustomerData>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/customers/${encodeURIComponent(String(customerId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetCustomerEvents(requestParameters: AdminControllerGetCustomerEventsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PaginatedCustomerEvents>;
    public adminControllerGetCustomerEvents(requestParameters: AdminControllerGetCustomerEventsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PaginatedCustomerEvents>>;
    public adminControllerGetCustomerEvents(requestParameters: AdminControllerGetCustomerEventsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PaginatedCustomerEvents>>;
    public adminControllerGetCustomerEvents(requestParameters: AdminControllerGetCustomerEventsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetCustomerEvents.');
        }
        const customerId = requestParameters.customerId;
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling adminControllerGetCustomerEvents.');
        }
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling adminControllerGetCustomerEvents.');
        }
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling adminControllerGetCustomerEvents.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PaginatedCustomerEvents>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/customers/${encodeURIComponent(String(customerId))}/events`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetCustomerToken(requestParameters: AdminControllerGetCustomerTokenRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AccessToken>;
    public adminControllerGetCustomerToken(requestParameters: AdminControllerGetCustomerTokenRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AccessToken>>;
    public adminControllerGetCustomerToken(requestParameters: AdminControllerGetCustomerTokenRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AccessToken>>;
    public adminControllerGetCustomerToken(requestParameters: AdminControllerGetCustomerTokenRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const email = requestParameters.email;
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling adminControllerGetCustomerToken.');
        }
        const tokenType = requestParameters.tokenType;
        if (tokenType === null || tokenType === undefined) {
            throw new Error('Required parameter tokenType was null or undefined when calling adminControllerGetCustomerToken.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetCustomerToken.');
        }
        const expiresIn = requestParameters.expiresIn;
        const apiKey = requestParameters.apiKey;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }
        if (tokenType !== undefined && tokenType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>tokenType, 'token_type');
        }
        if (expiresIn !== undefined && expiresIn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>expiresIn, 'expires_in');
        }

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('api-key', String(apiKey));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AccessToken>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/customers/token`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetFeatures(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Feature>>;
    public adminControllerGetFeatures(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Feature>>>;
    public adminControllerGetFeatures(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Feature>>>;
    public adminControllerGetFeatures(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Feature>>(`${this.configuration.basePath}/v1/program/features`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetIntegrations(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Integration>>;
    public adminControllerGetIntegrations(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Integration>>>;
    public adminControllerGetIntegrations(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Integration>>>;
    public adminControllerGetIntegrations(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Integration>>(`${this.configuration.basePath}/v1/program/integrations`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetProgramApiKeys(requestParameters: AdminControllerGetProgramApiKeysRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ApiKey>>;
    public adminControllerGetProgramApiKeys(requestParameters: AdminControllerGetProgramApiKeysRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ApiKey>>>;
    public adminControllerGetProgramApiKeys(requestParameters: AdminControllerGetProgramApiKeysRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ApiKey>>>;
    public adminControllerGetProgramApiKeys(requestParameters: AdminControllerGetProgramApiKeysRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetProgramApiKeys.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ApiKey>>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/api-keys`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetProgramCampaigns(requestParameters: AdminControllerGetProgramCampaignsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<CampaignDto>>;
    public adminControllerGetProgramCampaigns(requestParameters: AdminControllerGetProgramCampaignsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<CampaignDto>>>;
    public adminControllerGetProgramCampaigns(requestParameters: AdminControllerGetProgramCampaignsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<CampaignDto>>>;
    public adminControllerGetProgramCampaigns(requestParameters: AdminControllerGetProgramCampaignsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetProgramCampaigns.');
        }
        const type = requestParameters.type;
        const withDeleted = requestParameters.withDeleted;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }
        if (withDeleted !== undefined && withDeleted !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>withDeleted, 'with_deleted');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<CampaignDto>>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetProgramCustomers(requestParameters: AdminControllerGetProgramCustomersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PaginatedCustomers>;
    public adminControllerGetProgramCustomers(requestParameters: AdminControllerGetProgramCustomersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PaginatedCustomers>>;
    public adminControllerGetProgramCustomers(requestParameters: AdminControllerGetProgramCustomersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PaginatedCustomers>>;
    public adminControllerGetProgramCustomers(requestParameters: AdminControllerGetProgramCustomersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const limit = requestParameters.limit;
        if (limit === null || limit === undefined) {
            throw new Error('Required parameter limit was null or undefined when calling adminControllerGetProgramCustomers.');
        }
        const page = requestParameters.page;
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling adminControllerGetProgramCustomers.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetProgramCustomers.');
        }
        const searchTerm = requestParameters.searchTerm;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (searchTerm !== undefined && searchTerm !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>searchTerm, 'search_term');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PaginatedCustomers>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/customers`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetProgramLinks(requestParameters: AdminControllerGetProgramLinksRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Link>>;
    public adminControllerGetProgramLinks(requestParameters: AdminControllerGetProgramLinksRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Link>>>;
    public adminControllerGetProgramLinks(requestParameters: AdminControllerGetProgramLinksRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Link>>>;
    public adminControllerGetProgramLinks(requestParameters: AdminControllerGetProgramLinksRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const visibility = requestParameters.visibility;
        if (visibility === null || visibility === undefined) {
            throw new Error('Required parameter visibility was null or undefined when calling adminControllerGetProgramLinks.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetProgramLinks.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (visibility !== undefined && visibility !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>visibility, 'visibility');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Link>>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/links`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetProgramLocations(requestParameters: AdminControllerGetProgramLocationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Location>>;
    public adminControllerGetProgramLocations(requestParameters: AdminControllerGetProgramLocationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Location>>>;
    public adminControllerGetProgramLocations(requestParameters: AdminControllerGetProgramLocationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Location>>>;
    public adminControllerGetProgramLocations(requestParameters: AdminControllerGetProgramLocationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetProgramLocations.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Location>>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/locations`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetProgramSubscriptionInfo(requestParameters: AdminControllerGetProgramSubscriptionInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<SubscriptionInfo>;
    public adminControllerGetProgramSubscriptionInfo(requestParameters: AdminControllerGetProgramSubscriptionInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<SubscriptionInfo>>;
    public adminControllerGetProgramSubscriptionInfo(requestParameters: AdminControllerGetProgramSubscriptionInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<SubscriptionInfo>>;
    public adminControllerGetProgramSubscriptionInfo(requestParameters: AdminControllerGetProgramSubscriptionInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetProgramSubscriptionInfo.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SubscriptionInfo>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/subscriptions`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerGetSegments(requestParameters: AdminControllerGetSegmentsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<SegmentDto>>;
    public adminControllerGetSegments(requestParameters: AdminControllerGetSegmentsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<SegmentDto>>>;
    public adminControllerGetSegments(requestParameters: AdminControllerGetSegmentsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<SegmentDto>>>;
    public adminControllerGetSegments(requestParameters: AdminControllerGetSegmentsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerGetSegments.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SegmentDto>>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/segments`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerPatchProgramLinks(requestParameters: AdminControllerPatchProgramLinksRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerPatchProgramLinks(requestParameters: AdminControllerPatchProgramLinksRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerPatchProgramLinks(requestParameters: AdminControllerPatchProgramLinksRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerPatchProgramLinks(requestParameters: AdminControllerPatchProgramLinksRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const link = requestParameters.link;
        if (link === null || link === undefined) {
            throw new Error('Required parameter link was null or undefined when calling adminControllerPatchProgramLinks.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerPatchProgramLinks.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/links`,
            link,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerReactivateCampaign(requestParameters: AdminControllerReactivateCampaignRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CampaignDto>;
    public adminControllerReactivateCampaign(requestParameters: AdminControllerReactivateCampaignRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CampaignDto>>;
    public adminControllerReactivateCampaign(requestParameters: AdminControllerReactivateCampaignRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CampaignDto>>;
    public adminControllerReactivateCampaign(requestParameters: AdminControllerReactivateCampaignRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerReactivateCampaign.');
        }
        const campaignId = requestParameters.campaignId;
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling adminControllerReactivateCampaign.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<CampaignDto>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns/${encodeURIComponent(String(campaignId))}/reactivate`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerRemovePointsToCustomer(requestParameters: AdminControllerRemovePointsToCustomerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CustomerData>;
    public adminControllerRemovePointsToCustomer(requestParameters: AdminControllerRemovePointsToCustomerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CustomerData>>;
    public adminControllerRemovePointsToCustomer(requestParameters: AdminControllerRemovePointsToCustomerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CustomerData>>;
    public adminControllerRemovePointsToCustomer(requestParameters: AdminControllerRemovePointsToCustomerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createBooking = requestParameters.createBooking;
        if (createBooking === null || createBooking === undefined) {
            throw new Error('Required parameter createBooking was null or undefined when calling adminControllerRemovePointsToCustomer.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerRemovePointsToCustomer.');
        }
        const customerId = requestParameters.customerId;
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling adminControllerRemovePointsToCustomer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<CustomerData>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/customers/${encodeURIComponent(String(customerId))}/remove-balance`,
            createBooking,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerUpdateCampaign(requestParameters: AdminControllerUpdateCampaignRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CampaignDto>;
    public adminControllerUpdateCampaign(requestParameters: AdminControllerUpdateCampaignRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CampaignDto>>;
    public adminControllerUpdateCampaign(requestParameters: AdminControllerUpdateCampaignRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CampaignDto>>;
    public adminControllerUpdateCampaign(requestParameters: AdminControllerUpdateCampaignRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateCampaign = requestParameters.updateCampaign;
        if (updateCampaign === null || updateCampaign === undefined) {
            throw new Error('Required parameter updateCampaign was null or undefined when calling adminControllerUpdateCampaign.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerUpdateCampaign.');
        }
        const campaignId = requestParameters.campaignId;
        if (campaignId === null || campaignId === undefined) {
            throw new Error('Required parameter campaignId was null or undefined when calling adminControllerUpdateCampaign.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<CampaignDto>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/campaigns/${encodeURIComponent(String(campaignId))}`,
            updateCampaign,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerUpdateLink(requestParameters: AdminControllerUpdateLinkRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerUpdateLink(requestParameters: AdminControllerUpdateLinkRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerUpdateLink(requestParameters: AdminControllerUpdateLinkRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerUpdateLink(requestParameters: AdminControllerUpdateLinkRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const updateLink = requestParameters.updateLink;
        if (updateLink === null || updateLink === undefined) {
            throw new Error('Required parameter updateLink was null or undefined when calling adminControllerUpdateLink.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerUpdateLink.');
        }
        const linkId = requestParameters.linkId;
        if (linkId === null || linkId === undefined) {
            throw new Error('Required parameter linkId was null or undefined when calling adminControllerUpdateLink.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/links/${encodeURIComponent(String(linkId))}`,
            updateLink,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerUpdateLocation(requestParameters: AdminControllerUpdateLocationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public adminControllerUpdateLocation(requestParameters: AdminControllerUpdateLocationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public adminControllerUpdateLocation(requestParameters: AdminControllerUpdateLocationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public adminControllerUpdateLocation(requestParameters: AdminControllerUpdateLocationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const updateLocation = requestParameters.updateLocation;
        if (updateLocation === null || updateLocation === undefined) {
            throw new Error('Required parameter updateLocation was null or undefined when calling adminControllerUpdateLocation.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerUpdateLocation.');
        }
        const locationId = requestParameters.locationId;
        if (locationId === null || locationId === undefined) {
            throw new Error('Required parameter locationId was null or undefined when calling adminControllerUpdateLocation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/locations/${encodeURIComponent(String(locationId))}`,
            updateLocation,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerUpdateProgram(requestParameters: AdminControllerUpdateProgramRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Program>;
    public adminControllerUpdateProgram(requestParameters: AdminControllerUpdateProgramRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Program>>;
    public adminControllerUpdateProgram(requestParameters: AdminControllerUpdateProgramRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Program>>;
    public adminControllerUpdateProgram(requestParameters: AdminControllerUpdateProgramRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateProgram = requestParameters.updateProgram;
        if (updateProgram === null || updateProgram === undefined) {
            throw new Error('Required parameter updateProgram was null or undefined when calling adminControllerUpdateProgram.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerUpdateProgram.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<Program>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}`,
            updateProgram,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public adminControllerUpdateProgramIntegration(requestParameters: AdminControllerUpdateProgramIntegrationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProgramIntegration>;
    public adminControllerUpdateProgramIntegration(requestParameters: AdminControllerUpdateProgramIntegrationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProgramIntegration>>;
    public adminControllerUpdateProgramIntegration(requestParameters: AdminControllerUpdateProgramIntegrationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProgramIntegration>>;
    public adminControllerUpdateProgramIntegration(requestParameters: AdminControllerUpdateProgramIntegrationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateIntegration = requestParameters.updateIntegration;
        if (updateIntegration === null || updateIntegration === undefined) {
            throw new Error('Required parameter updateIntegration was null or undefined when calling adminControllerUpdateProgramIntegration.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling adminControllerUpdateProgramIntegration.');
        }
        const programIntegrationId = requestParameters.programIntegrationId;
        if (programIntegrationId === null || programIntegrationId === undefined) {
            throw new Error('Required parameter programIntegrationId was null or undefined when calling adminControllerUpdateProgramIntegration.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<ProgramIntegration>(`${this.configuration.basePath}/v1/program/${encodeURIComponent(String(programId))}/integrations/${encodeURIComponent(String(programIntegrationId))}`,
            updateIntegration,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
