import { createReducer, on } from "@ngrx/store";
import { AppState, initialState } from "./state";
import * as appActions from "./actions";
import { AppRoutes, CardType, WalletPlatform, WalletSideVisible } from "../../enums";
import { jwtDecode } from "jwt-decode";

export const appReducer = createReducer(
  initialState,

  on(appActions.setAccessToken, (state, { accessToken }): AppState => {
    let userId: string;

    try {
      const payload = jwtDecode(accessToken);
      userId = payload.sub;
    } catch (error) {}

    return { ...state, accessToken, userId };
  }),

  on(appActions.setIframeHost, (state, { iframeHost }): AppState => ({ ...state, iframeHost })),

  on(appActions.setIframeShop, (state, { iframeShop }): AppState => ({ ...state, iframeShop })),

  on(appActions.setIframeProgramId, (state, { iframeProgramId }): AppState => ({ ...state, iframeProgramId })),

  on(appActions.setIframeProgramName, (state, { iframeProgramName }): AppState => ({ ...state, iframeProgramName })),

  on(appActions.setIframeIntegrationName, (state, { iframeIntegrationName }): AppState => ({ ...state, iframeIntegrationName })),

  on(appActions.logOut, (state): AppState => ({ ...state, accessToken: null })),

  on(appActions.setDirtyForm, (state, { dirtyForm }): AppState => ({ ...state, dirtyForm })),

  on(appActions.setUploadImageLoading, (state, { uploadImageLoading }): AppState => ({ ...state, uploadImageLoading })),

  on(appActions.changeLanguageSuccess, (state, { language }): AppState => ({ ...state, language })),

  on(
    appActions.setAnalyticsFilters,
    (state, { type, ...analyticsFilters }): AppState => ({
      ...state,
      analyticsFilters: {
        ...state.analyticsFilters,
        ...analyticsFilters,
      },
    }),
  ),

  on(appActions.setDesignWalletPlatform, (state, { wallet }) => ({
    ...state,
    pages: {
      ...state.pages,
      [AppRoutes.design]: {
        ...state.pages[AppRoutes.design],
        walletPlatform:
          wallet || (state.pages[AppRoutes.design].walletPlatform === WalletPlatform.Apple ? WalletPlatform.Google : WalletPlatform.Apple),
      },
    },
  })),

  on(appActions.setDesignWalletSideVisible, (state, { side }) => ({
    ...state,
    pages: {
      ...state.pages,
      [AppRoutes.design]: {
        ...state.pages[AppRoutes.design],
        walletSideVisible:
          side ||
          (state.pages[AppRoutes.design].walletSideVisible === WalletSideVisible.Front ? WalletSideVisible.Back : WalletSideVisible.Front),
      },
    },
  })),

  on(appActions.setScheduledWalletPlatform, (state, { wallet }) => ({
    ...state,
    pages: {
      ...state.pages,
      [AppRoutes.scheduled]: {
        ...state.pages[AppRoutes.scheduled],
        walletPlatform:
          wallet ||
          (state.pages[AppRoutes.scheduled].walletPlatform === WalletPlatform.Apple ? WalletPlatform.Google : WalletPlatform.Apple),
      },
    },
  })),

  on(appActions.setTriggeredBasedWalletPlatform, (state, { wallet }) => ({
    ...state,
    pages: {
      ...state.pages,
      [AppRoutes.triggeredBased]: {
        ...state.pages[AppRoutes.triggeredBased],
        walletPlatform:
          wallet ||
          (state.pages[AppRoutes.triggeredBased].walletPlatform === WalletPlatform.Apple ? WalletPlatform.Google : WalletPlatform.Apple),
      },
    },
  })),

  on(appActions.setAnonymousWalletPlatform, (state, { wallet }) => ({
    ...state,
    pages: {
      ...state.pages,
      [AppRoutes.anonymous]: {
        ...state.pages[AppRoutes.anonymous],
        walletPlatform:
          wallet ||
          (state.pages[AppRoutes.anonymous].walletPlatform === WalletPlatform.Apple ? WalletPlatform.Google : WalletPlatform.Apple),
      },
    },
  })),

  on(appActions.setTriggeredBasedType, (state, { cardType }) => ({
    ...state,
    pages: {
      ...state.pages,
      [AppRoutes.triggeredBased]: {
        ...state.pages[AppRoutes.triggeredBased],
        type: cardType || (state.pages[AppRoutes.triggeredBased].type === CardType.notification ? CardType.wallet : CardType.notification),
      },
    },
  })),

  on(appActions.toggleSideNav, (state, { open }) => ({
    ...state,
    sideNav: {
      ...state.sideNav,
      open: open !== undefined ? open : !state.sideNav.open,
    },
  })),

  on(appActions.mobileSideNav, (state, { isMobile: mobile }) => ({
    ...state,
    sideNav: {
      ...state.sideNav,
      mobile,
    },
  })),

  on(appActions.setSideNav, (state, { active, hasLogOut }) => ({
    ...state,
    sideNav: {
      ...state.sideNav,
      active,
      hasLogOut,
    },
  })),
);
