import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutes, AppRouteParams } from "./enums";
import { Program } from "./services/api";
import { OnboardingCompleteGuard } from "./guards/onboarding-complete/onboarding-complete.guard";

export const routes: Routes = [
  {
    path: AppRoutes.notFound,
    loadChildren: () => import("./pages/not-found/not-found.module").then(m => m.NotFoundPageModule),
  },
  {
    path: AppRoutes.onboarding,
    loadChildren: () => import("./pages/onboarding/onboarding.module").then(m => m.OnboardingPageComponentModule),
  },
  {
    path: `${Program.IntegrationEnum.Klaviyo}/${AppRoutes.oauth}/${AppRoutes.callback}`,
    loadChildren: () => import("./pages/klaviyo-callback/klaviyo-callback.module").then(m => m.KlaviyoCallbackPageModule),
  },
  {
    path: `:${AppRouteParams.programSlug}`,
    canActivate: [OnboardingCompleteGuard],
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/main-app/main-app.module").then(m => m.MainAppPageModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: AppRoutes.notFound,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: "always" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
