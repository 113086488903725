import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PositiveNumbersDirective } from "./positive-numbers.directive";

@NgModule({
  declarations: [PositiveNumbersDirective],
  imports: [CommonModule],
  exports: [PositiveNumbersDirective],
})
export class PositiveNumbersDirectiveModule {}
