import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UpdateIntegrationConfirmData, UpdateIntegrationConfirmResult } from "./update-integration-confirm.interface";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-update-integration-confirm",
  templateUrl: "./update-integration-confirm.component.html",
  styleUrls: ["./update-integration-confirm.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateIntegrationConfirmComponent implements OnInit {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UpdateIntegrationConfirmData,
    private readonly dialogRef: MatDialogRef<UpdateIntegrationConfirmComponent, UpdateIntegrationConfirmResult>,
    private readonly translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.form = new FormGroup({
      input: new FormControl<string>("", [
        Validators.required,
        Validators.pattern(this.translateService.instant("UPDATE_INTEGRATION_CONFIRM.input")),
      ]),
    });
  }

  public confirm(confirm: boolean): void {
    this.dialogRef.close({ confirm });
  }
}
