import { Injectable } from "@angular/core";
import createApp, { ClientApplication } from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { ShopifyAppBridge } from "../../interfaces";
import { Store } from "@ngrx/store";
import { RootState, appActions, fromApp } from "../../store";
import { Program } from "../api";
import { Languages } from "../../enums";
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "../../constants";
import { Observable, combineLatest, filter, first, tap } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ShopifyAppBridgeService {
  private app: ClientApplication;

  public readonly iframeHost$: Observable<string> = this.store.select(fromApp.selectIframeHost);
  public readonly iframeShop$: Observable<string> = this.store.select(fromApp.selectIframeShop);
  public readonly iframeProgramId$: Observable<string> = this.store.select(fromApp.selectIframeProgramId);

  constructor(private readonly store: Store<RootState>) {
    combineLatest([this.iframeHost$, this.iframeShop$, this.iframeProgramId$])
      .pipe(
        first(),
        filter(([host, shop, programId]) => !!host && !!shop && !!programId),
        tap(([host, shop, programId]) => {
          this.initializeAppBridge({ apiKey: environment.shopifyClientId, host }, programId, shop);
        }),
      )
      .subscribe();
  }

  public async initializeAppBridge(params: ShopifyAppBridge, program: string, iframeShop: string): Promise<void> {
    this.app = createApp(params);

    this.setLanguage();

    const sessionToken = await this.getSessionToken();

    this.store.dispatch(appActions.setAccessToken({ accessToken: sessionToken }));
    this.store.dispatch(appActions.setSideNav({ active: true, hasLogOut: false }));
    this.store.dispatch(appActions.setIframeProgramId({ iframeProgramId: program }));
    this.store.dispatch(appActions.setIframeProgramName({ iframeProgramName: program }));
    this.store.dispatch(appActions.setIframeIntegrationName({ iframeIntegrationName: Program.IntegrationEnum.Shopify }));
    this.store.dispatch(appActions.setIframeShop({ iframeShop }));
    this.store.dispatch(appActions.setIframeHost({ iframeHost: params.host }));
  }

  public async getSessionToken(): Promise<string> {
    if (!this.app) return null;
    return getSessionToken(this.app);
  }

  private async setLanguage(): Promise<void> {
    const state = await this.app?.getState();
    const locale = state?.staffMember?.locale;
    const userLang = locale?.split("-")[0] as Languages;
    this.store.dispatch(appActions.changeLanguage({ language: AVAILABLE_LANGUAGES.includes(userLang) ? userLang : DEFAULT_LANGUAGE }));
  }
}
