/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1238
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { ProgramAnalytics } from '../model/models';
import { ProgramAnalyticsAggregate } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    AnalyticsServiceInterface,
    AnalyticsControllerGetAnalyticsRequestParams,
    AnalyticsControllerGetAnalyticsAggregateRequestParams
} from './analytics.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements AnalyticsServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public analyticsControllerGetAnalytics(requestParameters: AnalyticsControllerGetAnalyticsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProgramAnalytics>;
    public analyticsControllerGetAnalytics(requestParameters: AnalyticsControllerGetAnalyticsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProgramAnalytics>>;
    public analyticsControllerGetAnalytics(requestParameters: AnalyticsControllerGetAnalyticsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProgramAnalytics>>;
    public analyticsControllerGetAnalytics(requestParameters: AnalyticsControllerGetAnalyticsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const fromDate = requestParameters.fromDate;
        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling analyticsControllerGetAnalytics.');
        }
        const toDate = requestParameters.toDate;
        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling analyticsControllerGetAnalytics.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling analyticsControllerGetAnalytics.');
        }
        const campaignId = requestParameters.campaignId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fromDate !== undefined && fromDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromDate, 'from_date');
        }
        if (toDate !== undefined && toDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDate, 'to_date');
        }
        if (campaignId !== undefined && campaignId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>campaignId, 'campaign_id');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramAnalytics>(`${this.configuration.basePath}/v1/analytics/${encodeURIComponent(String(programId))}/analytics`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public analyticsControllerGetAnalyticsAggregate(requestParameters: AnalyticsControllerGetAnalyticsAggregateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ProgramAnalyticsAggregate>;
    public analyticsControllerGetAnalyticsAggregate(requestParameters: AnalyticsControllerGetAnalyticsAggregateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ProgramAnalyticsAggregate>>;
    public analyticsControllerGetAnalyticsAggregate(requestParameters: AnalyticsControllerGetAnalyticsAggregateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ProgramAnalyticsAggregate>>;
    public analyticsControllerGetAnalyticsAggregate(requestParameters: AnalyticsControllerGetAnalyticsAggregateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const fromDate = requestParameters.fromDate;
        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling analyticsControllerGetAnalyticsAggregate.');
        }
        const toDate = requestParameters.toDate;
        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling analyticsControllerGetAnalyticsAggregate.');
        }
        const information = requestParameters.information;
        if (information === null || information === undefined) {
            throw new Error('Required parameter information was null or undefined when calling analyticsControllerGetAnalyticsAggregate.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling analyticsControllerGetAnalyticsAggregate.');
        }
        const campaignId = requestParameters.campaignId;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (fromDate !== undefined && fromDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>fromDate, 'from_date');
        }
        if (toDate !== undefined && toDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>toDate, 'to_date');
        }
        if (information !== undefined && information !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>information, 'information');
        }
        if (campaignId !== undefined && campaignId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>campaignId, 'campaign_id');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramAnalyticsAggregate>(`${this.configuration.basePath}/v1/analytics/${encodeURIComponent(String(programId))}/analytics/aggregate`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
