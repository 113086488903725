import { appActions, initialRootState } from "../..";
import { ActionReducer, INIT } from "@ngrx/store";

export const logoutMetaReducer =
  <State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> =>
    (state, action) =>
      action.type === appActions.logOutSuccess.type
        ? reducer(
          {
            ...initialRootState,
            app: {
              ...initialRootState.app,
            },
          } as never,
          { type: INIT },
        )
        : reducer(state, action);
