/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, combineLatest, first, switchMap } from "rxjs";
import { Program } from "../../services/api";
import { RootState, fromApp, fromProgram } from "../../store";
import { Store } from "@ngrx/store";

@Injectable({ providedIn: "root" })
export class AppInterceptor implements HttpInterceptor {
  private readonly userId$: Observable<string> = this.store.select(fromApp.selectUserId);
  private readonly program$: Observable<Program> = this.store.select(fromProgram.selectProgram);

  constructor(private readonly store: Store<RootState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.userId$, this.program$]).pipe(
      first(),
      switchMap(([userId, program]) => {
        req = req.clone({ setHeaders: { "X-APP": "admin" } });

        if (userId) req = req.clone({ setHeaders: { "X-USER-ID": userId } });
        if (program?.id) req = req.clone({ setHeaders: { "X-PROGRAM-ID": program?.id } });
        if (program?.slug) req = req.clone({ setHeaders: { "X-PROGRAM-SLUG": program?.slug } });

        return next.handle(req);
      }),
    );
  }
}
