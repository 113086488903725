/* eslint-disable @typescript-eslint/no-explicit-any */
import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { ActionReducerMap, MetaReducer } from "@ngrx/store";

import { APP_FEATURE_KEY, AppState, initialState as app } from "./app/state";
import { PROGRAM_FEATURE_KEY, ProgramState, initialState as program } from "./program/state";

import { appReducer } from "./app/reducer";
import { programReducer } from "./program/reducer";

import { AppEffects } from "./app/effects";
import { ProgramEffects } from "./program/effects";
import { logoutMetaReducer } from "./app/meta-reducers/logout.metareducer";
import { localStorageReduxSyncMetaReducer } from "./app/meta-reducers/local-storage.metareducer";

export interface RootState {
  [APP_FEATURE_KEY]: AppState;
  [PROGRAM_FEATURE_KEY]: ProgramState;
  router: RouterReducerState<any>;
}

export const initialRootState: RootState = {
  app,
  program,
  router: { navigationId: 0, state: {} },
};

export const reducers: ActionReducerMap<RootState> = {
  [APP_FEATURE_KEY]: appReducer,
  [PROGRAM_FEATURE_KEY]: programReducer,
  router: routerReducer,
};

export const effects = [AppEffects, ProgramEffects];

export const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSyncMetaReducer, logoutMetaReducer];

export * as appActions from "./app/actions";
export * as programActions from "./program/actions";

export * as fromApp from "./app/selectors";
export * as fromProgram from "./program/selectors";
export * as fromRouter from "./router/selectors";
