import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-form-field-wrapper",
  templateUrl: "./form-field-wrapper.component.html",
  styleUrls: ["./form-field-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldWrapperComponent {
  @Input() public size: 25 | 33 | 50 | 66 | 75 | 100;
}
