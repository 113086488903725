/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1238
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AccessToken } from '../model/models';
import { CheckCustomer } from '../model/models';
import { ClaimedReward } from '../model/models';
import { ClickDto } from '../model/models';
import { CreateClick } from '../model/models';
import { Customer } from '../model/models';
import { CustomerData } from '../model/models';
import { GooglePass } from '../model/models';
import { MultiPassLink } from '../model/models';
import { Program } from '../model/models';
import { ProgramIntegration } from '../model/models';
import { RegisterCustomer } from '../model/models';
import { Reward } from '../model/models';
import { SendPush } from '../model/models';
import { UpdateCustomerPreferences } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {
    ProgramServiceInterface,
    ProgramControllerActivateCustomerRewardRequestParams,
    ProgramControllerCheckCustomerExistsRequestParams,
    ProgramControllerClaimCouponCodeRequestParams,
    ProgramControllerClaimCustomerRewardRequestParams,
    ProgramControllerDeactivateCustomerRewardRequestParams,
    ProgramControllerGetAnonymousCouponApplePassRequestParams,
    ProgramControllerGetAnonymousCouponGooglePassRequestParams,
    ProgramControllerGetApplePassRequestParams,
    ProgramControllerGetCouponUniqueCodeApplePassRequestParams,
    ProgramControllerGetCouponUniqueCodeGooglePassRequestParams,
    ProgramControllerGetCustomerCouponsRequestParams,
    ProgramControllerGetCustomerDataRequestParams,
    ProgramControllerGetCustomerMultiPassLinkRequestParams,
    ProgramControllerGetGooglePassRequestParams,
    ProgramControllerGetProgramRequestParams,
    ProgramControllerGetProgramIntegrationsRequestParams,
    ProgramControllerRefundCustomerRewardRequestParams,
    ProgramControllerRegisterCustomerRequestParams,
    ProgramControllerSendPassToCustomerRequestParams,
    ProgramControllerSendPushRequestParams,
    ProgramControllerSendValidationEmailRequestParams,
    ProgramControllerStoreCustomerClickRequestParams,
    ProgramControllerUpdateCustomerPreferencesRequestParams,
    ProgramControllerValidateCustomerRequestParams
} from './program.serviceInterface';



@Injectable({
  providedIn: 'root'
})
export class ProgramService implements ProgramServiceInterface {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerActivateCustomerReward(requestParameters: ProgramControllerActivateCustomerRewardRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerActivateCustomerReward(requestParameters: ProgramControllerActivateCustomerRewardRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerActivateCustomerReward(requestParameters: ProgramControllerActivateCustomerRewardRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerActivateCustomerReward(requestParameters: ProgramControllerActivateCustomerRewardRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerActivateCustomerReward.');
        }
        const rewardId = requestParameters.rewardId;
        if (rewardId === null || rewardId === undefined) {
            throw new Error('Required parameter rewardId was null or undefined when calling programControllerActivateCustomerReward.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/customers/rewards/${encodeURIComponent(String(rewardId))}/activate`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerCheckCustomerExists(requestParameters: ProgramControllerCheckCustomerExistsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerCheckCustomerExists(requestParameters: ProgramControllerCheckCustomerExistsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerCheckCustomerExists(requestParameters: ProgramControllerCheckCustomerExistsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerCheckCustomerExists(requestParameters: ProgramControllerCheckCustomerExistsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const checkCustomer = requestParameters.checkCustomer;
        if (checkCustomer === null || checkCustomer === undefined) {
            throw new Error('Required parameter checkCustomer was null or undefined when calling programControllerCheckCustomerExists.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerCheckCustomerExists.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/check`,
            checkCustomer,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerClaimCouponCode(requestParameters: ProgramControllerClaimCouponCodeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerClaimCouponCode(requestParameters: ProgramControllerClaimCouponCodeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerClaimCouponCode(requestParameters: ProgramControllerClaimCouponCodeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerClaimCouponCode(requestParameters: ProgramControllerClaimCouponCodeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerClaimCouponCode.');
        }
        const couponId = requestParameters.couponId;
        if (couponId === null || couponId === undefined) {
            throw new Error('Required parameter couponId was null or undefined when calling programControllerClaimCouponCode.');
        }
        const couponCodeValue = requestParameters.couponCodeValue;
        if (couponCodeValue === null || couponCodeValue === undefined) {
            throw new Error('Required parameter couponCodeValue was null or undefined when calling programControllerClaimCouponCode.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/coupons/${encodeURIComponent(String(couponId))}/codes/${encodeURIComponent(String(couponCodeValue))}/claim`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerClaimCustomerReward(requestParameters: ProgramControllerClaimCustomerRewardRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ClaimedReward>;
    public programControllerClaimCustomerReward(requestParameters: ProgramControllerClaimCustomerRewardRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ClaimedReward>>;
    public programControllerClaimCustomerReward(requestParameters: ProgramControllerClaimCustomerRewardRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ClaimedReward>>;
    public programControllerClaimCustomerReward(requestParameters: ProgramControllerClaimCustomerRewardRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerClaimCustomerReward.');
        }
        const rewardId = requestParameters.rewardId;
        if (rewardId === null || rewardId === undefined) {
            throw new Error('Required parameter rewardId was null or undefined when calling programControllerClaimCustomerReward.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ClaimedReward>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/customers/rewards/${encodeURIComponent(String(rewardId))}/claim`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerDeactivateCustomerReward(requestParameters: ProgramControllerDeactivateCustomerRewardRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerDeactivateCustomerReward(requestParameters: ProgramControllerDeactivateCustomerRewardRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerDeactivateCustomerReward(requestParameters: ProgramControllerDeactivateCustomerRewardRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerDeactivateCustomerReward(requestParameters: ProgramControllerDeactivateCustomerRewardRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerDeactivateCustomerReward.');
        }
        const rewardId = requestParameters.rewardId;
        if (rewardId === null || rewardId === undefined) {
            throw new Error('Required parameter rewardId was null or undefined when calling programControllerDeactivateCustomerReward.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/customers/rewards/${encodeURIComponent(String(rewardId))}/deactivate`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetAnonymousCouponApplePass(requestParameters: ProgramControllerGetAnonymousCouponApplePassRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerGetAnonymousCouponApplePass(requestParameters: ProgramControllerGetAnonymousCouponApplePassRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerGetAnonymousCouponApplePass(requestParameters: ProgramControllerGetAnonymousCouponApplePassRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerGetAnonymousCouponApplePass(requestParameters: ProgramControllerGetAnonymousCouponApplePassRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetAnonymousCouponApplePass.');
        }
        const couponId = requestParameters.couponId;
        if (couponId === null || couponId === undefined) {
            throw new Error('Required parameter couponId was null or undefined when calling programControllerGetAnonymousCouponApplePass.');
        }
        const campaignSlug = requestParameters.campaignSlug;
        if (campaignSlug === null || campaignSlug === undefined) {
            throw new Error('Required parameter campaignSlug was null or undefined when calling programControllerGetAnonymousCouponApplePass.');
        }
        const couponCode = requestParameters.couponCode;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (campaignSlug !== undefined && campaignSlug !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>campaignSlug, 'campaign-slug');
        }
        if (couponCode !== undefined && couponCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>couponCode, 'coupon-code');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/anonymous-coupons/${encodeURIComponent(String(couponId))}/apple/pass`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetAnonymousCouponGooglePass(requestParameters: ProgramControllerGetAnonymousCouponGooglePassRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerGetAnonymousCouponGooglePass(requestParameters: ProgramControllerGetAnonymousCouponGooglePassRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerGetAnonymousCouponGooglePass(requestParameters: ProgramControllerGetAnonymousCouponGooglePassRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerGetAnonymousCouponGooglePass(requestParameters: ProgramControllerGetAnonymousCouponGooglePassRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetAnonymousCouponGooglePass.');
        }
        const couponId = requestParameters.couponId;
        if (couponId === null || couponId === undefined) {
            throw new Error('Required parameter couponId was null or undefined when calling programControllerGetAnonymousCouponGooglePass.');
        }
        const campaignSlug = requestParameters.campaignSlug;
        if (campaignSlug === null || campaignSlug === undefined) {
            throw new Error('Required parameter campaignSlug was null or undefined when calling programControllerGetAnonymousCouponGooglePass.');
        }
        const couponCode = requestParameters.couponCode;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (campaignSlug !== undefined && campaignSlug !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>campaignSlug, 'campaign-slug');
        }
        if (couponCode !== undefined && couponCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>couponCode, 'coupon-code');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/anonymous-coupons/${encodeURIComponent(String(couponId))}/google/pass`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetApplePass(requestParameters: ProgramControllerGetApplePassRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerGetApplePass(requestParameters: ProgramControllerGetApplePassRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerGetApplePass(requestParameters: ProgramControllerGetApplePassRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerGetApplePass(requestParameters: ProgramControllerGetApplePassRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetApplePass.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/apple/pass`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetCouponUniqueCodeApplePass(requestParameters: ProgramControllerGetCouponUniqueCodeApplePassRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerGetCouponUniqueCodeApplePass(requestParameters: ProgramControllerGetCouponUniqueCodeApplePassRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerGetCouponUniqueCodeApplePass(requestParameters: ProgramControllerGetCouponUniqueCodeApplePassRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerGetCouponUniqueCodeApplePass(requestParameters: ProgramControllerGetCouponUniqueCodeApplePassRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetCouponUniqueCodeApplePass.');
        }
        const couponId = requestParameters.couponId;
        if (couponId === null || couponId === undefined) {
            throw new Error('Required parameter couponId was null or undefined when calling programControllerGetCouponUniqueCodeApplePass.');
        }
        const couponCodeValue = requestParameters.couponCodeValue;
        if (couponCodeValue === null || couponCodeValue === undefined) {
            throw new Error('Required parameter couponCodeValue was null or undefined when calling programControllerGetCouponUniqueCodeApplePass.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/coupons/${encodeURIComponent(String(couponId))}/codes/${encodeURIComponent(String(couponCodeValue))}/apple/pass`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetCouponUniqueCodeGooglePass(requestParameters: ProgramControllerGetCouponUniqueCodeGooglePassRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerGetCouponUniqueCodeGooglePass(requestParameters: ProgramControllerGetCouponUniqueCodeGooglePassRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerGetCouponUniqueCodeGooglePass(requestParameters: ProgramControllerGetCouponUniqueCodeGooglePassRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerGetCouponUniqueCodeGooglePass(requestParameters: ProgramControllerGetCouponUniqueCodeGooglePassRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetCouponUniqueCodeGooglePass.');
        }
        const couponId = requestParameters.couponId;
        if (couponId === null || couponId === undefined) {
            throw new Error('Required parameter couponId was null or undefined when calling programControllerGetCouponUniqueCodeGooglePass.');
        }
        const couponCodeValue = requestParameters.couponCodeValue;
        if (couponCodeValue === null || couponCodeValue === undefined) {
            throw new Error('Required parameter couponCodeValue was null or undefined when calling programControllerGetCouponUniqueCodeGooglePass.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/coupons/${encodeURIComponent(String(couponId))}/codes/${encodeURIComponent(String(couponCodeValue))}/google/pass`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetCustomerCoupons(requestParameters: ProgramControllerGetCustomerCouponsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<Reward>>;
    public programControllerGetCustomerCoupons(requestParameters: ProgramControllerGetCustomerCouponsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<Reward>>>;
    public programControllerGetCustomerCoupons(requestParameters: ProgramControllerGetCustomerCouponsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<Reward>>>;
    public programControllerGetCustomerCoupons(requestParameters: ProgramControllerGetCustomerCouponsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const xAPPLANGUAGE = requestParameters.xAPPLANGUAGE;
        if (xAPPLANGUAGE === null || xAPPLANGUAGE === undefined) {
            throw new Error('Required parameter xAPPLANGUAGE was null or undefined when calling programControllerGetCustomerCoupons.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetCustomerCoupons.');
        }

        let headers = this.defaultHeaders;
        if (xAPPLANGUAGE !== undefined && xAPPLANGUAGE !== null) {
            headers = headers.set('X-APP-LANGUAGE', String(xAPPLANGUAGE));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Reward>>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/customers/rewards`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetCustomerData(requestParameters: ProgramControllerGetCustomerDataRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CustomerData>;
    public programControllerGetCustomerData(requestParameters: ProgramControllerGetCustomerDataRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CustomerData>>;
    public programControllerGetCustomerData(requestParameters: ProgramControllerGetCustomerDataRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CustomerData>>;
    public programControllerGetCustomerData(requestParameters: ProgramControllerGetCustomerDataRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetCustomerData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<CustomerData>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/customers`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetCustomerMultiPassLink(requestParameters: ProgramControllerGetCustomerMultiPassLinkRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<MultiPassLink>>;
    public programControllerGetCustomerMultiPassLink(requestParameters: ProgramControllerGetCustomerMultiPassLinkRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<MultiPassLink>>>;
    public programControllerGetCustomerMultiPassLink(requestParameters: ProgramControllerGetCustomerMultiPassLinkRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<MultiPassLink>>>;
    public programControllerGetCustomerMultiPassLink(requestParameters: ProgramControllerGetCustomerMultiPassLinkRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetCustomerMultiPassLink.');
        }
        const redirectUrl = requestParameters.redirectUrl;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (redirectUrl !== undefined && redirectUrl !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>redirectUrl, 'redirect_url');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<MultiPassLink>>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/customers/multi-pass-link`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetGooglePass(requestParameters: ProgramControllerGetGooglePassRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<GooglePass>;
    public programControllerGetGooglePass(requestParameters: ProgramControllerGetGooglePassRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<GooglePass>>;
    public programControllerGetGooglePass(requestParameters: ProgramControllerGetGooglePassRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<GooglePass>>;
    public programControllerGetGooglePass(requestParameters: ProgramControllerGetGooglePassRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetGooglePass.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<GooglePass>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/google/pass`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetProgram(requestParameters: ProgramControllerGetProgramRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Program>;
    public programControllerGetProgram(requestParameters: ProgramControllerGetProgramRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Program>>;
    public programControllerGetProgram(requestParameters: ProgramControllerGetProgramRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Program>>;
    public programControllerGetProgram(requestParameters: ProgramControllerGetProgramRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetProgram.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Program>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerGetProgramIntegrations(requestParameters: ProgramControllerGetProgramIntegrationsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ProgramIntegration>>;
    public programControllerGetProgramIntegrations(requestParameters: ProgramControllerGetProgramIntegrationsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ProgramIntegration>>>;
    public programControllerGetProgramIntegrations(requestParameters: ProgramControllerGetProgramIntegrationsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ProgramIntegration>>>;
    public programControllerGetProgramIntegrations(requestParameters: ProgramControllerGetProgramIntegrationsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerGetProgramIntegrations.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgramIntegration>>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/integrations`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerRefundCustomerReward(requestParameters: ProgramControllerRefundCustomerRewardRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerRefundCustomerReward(requestParameters: ProgramControllerRefundCustomerRewardRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerRefundCustomerReward(requestParameters: ProgramControllerRefundCustomerRewardRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerRefundCustomerReward(requestParameters: ProgramControllerRefundCustomerRewardRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerRefundCustomerReward.');
        }
        const claimId = requestParameters.claimId;
        if (claimId === null || claimId === undefined) {
            throw new Error('Required parameter claimId was null or undefined when calling programControllerRefundCustomerReward.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/customers/rewards/${encodeURIComponent(String(claimId))}/refund`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerRegisterCustomer(requestParameters: ProgramControllerRegisterCustomerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<AccessToken>;
    public programControllerRegisterCustomer(requestParameters: ProgramControllerRegisterCustomerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<AccessToken>>;
    public programControllerRegisterCustomer(requestParameters: ProgramControllerRegisterCustomerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<AccessToken>>;
    public programControllerRegisterCustomer(requestParameters: ProgramControllerRegisterCustomerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const registerCustomer = requestParameters.registerCustomer;
        if (registerCustomer === null || registerCustomer === undefined) {
            throw new Error('Required parameter registerCustomer was null or undefined when calling programControllerRegisterCustomer.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerRegisterCustomer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<AccessToken>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/register`,
            registerCustomer,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerSendPassToCustomer(requestParameters: ProgramControllerSendPassToCustomerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerSendPassToCustomer(requestParameters: ProgramControllerSendPassToCustomerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerSendPassToCustomer(requestParameters: ProgramControllerSendPassToCustomerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerSendPassToCustomer(requestParameters: ProgramControllerSendPassToCustomerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const checkCustomer = requestParameters.checkCustomer;
        if (checkCustomer === null || checkCustomer === undefined) {
            throw new Error('Required parameter checkCustomer was null or undefined when calling programControllerSendPassToCustomer.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerSendPassToCustomer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/send-pass`,
            checkCustomer,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerSendPush(requestParameters: ProgramControllerSendPushRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerSendPush(requestParameters: ProgramControllerSendPushRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerSendPush(requestParameters: ProgramControllerSendPushRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerSendPush(requestParameters: ProgramControllerSendPushRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const sendPush = requestParameters.sendPush;
        if (sendPush === null || sendPush === undefined) {
            throw new Error('Required parameter sendPush was null or undefined when calling programControllerSendPush.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerSendPush.');
        }
        const campaignId = requestParameters.campaignId;
        const apiKey = requestParameters.apiKey;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (campaignId !== undefined && campaignId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>campaignId, 'campaign_id');
        }

        let headers = this.defaultHeaders;
        if (apiKey !== undefined && apiKey !== null) {
            headers = headers.set('api-key', String(apiKey));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/send-push`,
            sendPush,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerSendValidationEmail(requestParameters: ProgramControllerSendValidationEmailRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public programControllerSendValidationEmail(requestParameters: ProgramControllerSendValidationEmailRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public programControllerSendValidationEmail(requestParameters: ProgramControllerSendValidationEmailRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public programControllerSendValidationEmail(requestParameters: ProgramControllerSendValidationEmailRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        const checkCustomer = requestParameters.checkCustomer;
        if (checkCustomer === null || checkCustomer === undefined) {
            throw new Error('Required parameter checkCustomer was null or undefined when calling programControllerSendValidationEmail.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerSendValidationEmail.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/send-validation-email`,
            checkCustomer,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerStoreCustomerClick(requestParameters: ProgramControllerStoreCustomerClickRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ClickDto>;
    public programControllerStoreCustomerClick(requestParameters: ProgramControllerStoreCustomerClickRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ClickDto>>;
    public programControllerStoreCustomerClick(requestParameters: ProgramControllerStoreCustomerClickRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ClickDto>>;
    public programControllerStoreCustomerClick(requestParameters: ProgramControllerStoreCustomerClickRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const createClick = requestParameters.createClick;
        if (createClick === null || createClick === undefined) {
            throw new Error('Required parameter createClick was null or undefined when calling programControllerStoreCustomerClick.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerStoreCustomerClick.');
        }
        const userAgent = requestParameters.userAgent;

        let headers = this.defaultHeaders;
        if (userAgent !== undefined && userAgent !== null) {
            headers = headers.set('user-agent', String(userAgent));
        }

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ClickDto>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/clicks`,
            createClick,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerUpdateCustomerPreferences(requestParameters: ProgramControllerUpdateCustomerPreferencesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Customer>;
    public programControllerUpdateCustomerPreferences(requestParameters: ProgramControllerUpdateCustomerPreferencesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Customer>>;
    public programControllerUpdateCustomerPreferences(requestParameters: ProgramControllerUpdateCustomerPreferencesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Customer>>;
    public programControllerUpdateCustomerPreferences(requestParameters: ProgramControllerUpdateCustomerPreferencesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const updateCustomerPreferences = requestParameters.updateCustomerPreferences;
        if (updateCustomerPreferences === null || updateCustomerPreferences === undefined) {
            throw new Error('Required parameter updateCustomerPreferences was null or undefined when calling programControllerUpdateCustomerPreferences.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerUpdateCustomerPreferences.');
        }
        const customerId = requestParameters.customerId;
        if (customerId === null || customerId === undefined) {
            throw new Error('Required parameter customerId was null or undefined when calling programControllerUpdateCustomerPreferences.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<Customer>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/customers/${encodeURIComponent(String(customerId))}/preferences`,
            updateCustomerPreferences,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programControllerValidateCustomer(requestParameters: ProgramControllerValidateCustomerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Customer>;
    public programControllerValidateCustomer(requestParameters: ProgramControllerValidateCustomerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Customer>>;
    public programControllerValidateCustomer(requestParameters: ProgramControllerValidateCustomerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Customer>>;
    public programControllerValidateCustomer(requestParameters: ProgramControllerValidateCustomerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        const checkCustomer = requestParameters.checkCustomer;
        if (checkCustomer === null || checkCustomer === undefined) {
            throw new Error('Required parameter checkCustomer was null or undefined when calling programControllerValidateCustomer.');
        }
        const programId = requestParameters.programId;
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling programControllerValidateCustomer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearer) required
        credential = this.configuration.lookupCredential('bearer');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Customer>(`${this.configuration.basePath}/v1/programs/${encodeURIComponent(String(programId))}/validate`,
            checkCustomer,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
