import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormFieldWrapperComponent } from "./form-field-wrapper.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
  declarations: [FormFieldWrapperComponent],
  exports: [FormFieldWrapperComponent],
})
export class FormFieldWrapperComponentModule {}
