import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GenerateApiKeyComponent } from "./generate-api-key.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { FormFieldWrapperComponentModule } from "../../components/form-field-wrapper/form-field-wrapper.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    FormFieldWrapperComponentModule,
  ],
  declarations: [GenerateApiKeyComponent],
  exports: [GenerateApiKeyComponent],
})
export class GenerateApiKeyComponentModule {}
