/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, catchError, combineLatest, first, from, mergeMap, switchMap, throwError } from "rxjs";
import { RootState, appActions, fromApp } from "../../store";
import { ShopifyAppBridgeService } from "../../services/shopify-app-bridge/shopify-app-bridge.service";
import { Program } from "../../services/api";

@Injectable({ providedIn: "root" })
export class ShopifyInterceptor implements HttpInterceptor {
  private readonly accessToken$: Observable<string> = this.store.select(fromApp.selectAccessToken);
  private readonly iframeIntegrationName$: Observable<string> = this.store.select(fromApp.selectIframeIntegrationName);

  constructor(
    private readonly store: Store<RootState>,
    private readonly shopifyAppBridgeService: ShopifyAppBridgeService,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.accessToken$, this.iframeIntegrationName$]).pipe(
      first(),
      switchMap(([accessToken, iframeIntegrationName]) => {
        if (iframeIntegrationName !== Program.IntegrationEnum.Shopify) return next.handle(req);

        req = req.clone({ setHeaders: { authorization: `Bearer ${accessToken}` } });

        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status >= HttpStatusCode.InternalServerError) console.error(error);
            if (error.status === HttpStatusCode.Unauthorized || error.status === 0) {
              return from(this.shopifyAppBridgeService.getSessionToken()).pipe(
                mergeMap(sessionToken => {
                  this.store.dispatch(appActions.setAccessToken({ accessToken: sessionToken }));
                  return next.handle(req.clone({ setHeaders: { authorization: `Bearer ${sessionToken}` } }));
                }),
                catchError((e: HttpErrorResponse) => {
                  if (e.status === HttpStatusCode.Unauthorized) this.store.dispatch(appActions.logOut());
                  return throwError(() => e.error);
                }),
              );
            }

            return throwError(() => error.error);
          }),
        );
      }),
    );
  }
}
