import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-info-banner",
  templateUrl: "./info-banner.component.html",
  styleUrls: ["./info-banner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoBannerComponent {
  @Input() public status: "error" | "warning" | "success" | "info";
  @Input() public hideClose: boolean;

  @Output() public readonly closeEvent: EventEmitter<void> = new EventEmitter();
}
