/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1238
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MinimalCampaignDto } from './minimal-campaign-dto';
import { MinimalPassDto } from './minimal-pass-dto';
import { MinimalNotificationDto } from './minimal-notification-dto';


export interface CustomerEventDto { 
    id: string;
    customerId: string;
    passId: string;
    campaignId: string;
    notificationId: string;
    type: CustomerEventDto.TypeEnum;
    properties: object;
    createdAt: string;
    pass: MinimalPassDto;
    campaign: MinimalCampaignDto;
    notification: MinimalNotificationDto;
}
export namespace CustomerEventDto {
    export type TypeEnum = 'CAMPAIGN_DISJOINED' | 'CAMPAIGN_JOINED' | 'COUPON_INSTALLED' | 'COUPON_PASS_CREATED' | 'COUPON_UNINSTALLED' | 'CUSTOMER_CREATED' | 'CUSTOMER_SCANNED' | 'PASS_CREATED' | 'PASS_INSTALLED' | 'PASS_REQUESTED' | 'PASS_UNINSTALLED' | 'PASS_UPDATED' | 'POINTS_ADDED' | 'POINTS_REMOVED' | 'PUSH_CREATED' | 'PUSH_FAILED' | 'PUSH_SENT';
    export const TypeEnum = {
        CampaignDisjoined: 'CAMPAIGN_DISJOINED' as TypeEnum,
        CampaignJoined: 'CAMPAIGN_JOINED' as TypeEnum,
        CouponInstalled: 'COUPON_INSTALLED' as TypeEnum,
        CouponPassCreated: 'COUPON_PASS_CREATED' as TypeEnum,
        CouponUninstalled: 'COUPON_UNINSTALLED' as TypeEnum,
        CustomerCreated: 'CUSTOMER_CREATED' as TypeEnum,
        CustomerScanned: 'CUSTOMER_SCANNED' as TypeEnum,
        PassCreated: 'PASS_CREATED' as TypeEnum,
        PassInstalled: 'PASS_INSTALLED' as TypeEnum,
        PassRequested: 'PASS_REQUESTED' as TypeEnum,
        PassUninstalled: 'PASS_UNINSTALLED' as TypeEnum,
        PassUpdated: 'PASS_UPDATED' as TypeEnum,
        PointsAdded: 'POINTS_ADDED' as TypeEnum,
        PointsRemoved: 'POINTS_REMOVED' as TypeEnum,
        PushCreated: 'PUSH_CREATED' as TypeEnum,
        PushFailed: 'PUSH_FAILED' as TypeEnum,
        PushSent: 'PUSH_SENT' as TypeEnum
    };
}


