import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UnderstoodData } from "./understood.interface";

@Component({
  selector: "app-understood",
  templateUrl: "./understood.component.html",
  styleUrls: ["./understood.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnderstoodComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UnderstoodData,
    private readonly dialogRef: MatDialogRef<UnderstoodComponent, void>,
  ) {}

  public understood(): void {
    this.dialogRef.close();
  }
}
