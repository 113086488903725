import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputVariablesComponent } from "./input-variables.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CouponSelectorComponentModule } from "./coupon-selector/coupon-selector.module";

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatIconModule,
    MatTooltipModule,
    CouponSelectorComponentModule,
  ],
  declarations: [InputVariablesComponent],
  exports: [InputVariablesComponent],
})
export class InputVariablesComponentModule {}
