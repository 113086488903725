import { createAction, props } from "@ngrx/store";
import { CardType, Languages, WalletPlatform, WalletSideVisible } from "../../enums";
import { Program } from "../../services/api";
import { AnalyticsFilters } from "./state";

export const setAccessToken = createAction("@app/set-access-token", props<{ accessToken: string }>());

export const setIframeHost = createAction("@app/set-iframe-host", props<{ iframeHost: string }>());

export const setIframeShop = createAction("@app/set-iframe-shop", props<{ iframeShop: string }>());

export const setIframeProgramId = createAction("@app/set-iframe-program-id", props<{ iframeProgramId: string }>());

export const setIframeProgramName = createAction("@app/set-iframe-program-name", props<{ iframeProgramName: string }>());

export const setIframeIntegrationName = createAction(
  "@app/set-iframe-integration-name",
  props<{ iframeIntegrationName: Program.IntegrationEnum }>(),
);

export const logOut = createAction("@app/log-out");

export const logOutSuccess = createAction("@app/log-out-success");

export const setDirtyForm = createAction("@app/set-dirty-form", props<{ dirtyForm: boolean }>());

export const setUploadImageLoading = createAction("@app/set-upload-image-loading", props<{ uploadImageLoading: boolean }>());

export const changeLanguage = createAction("@app/change-language", props<{ language: Languages }>());

export const changeLanguageSuccess = createAction("@app/change-language-success", props<{ language: Languages }>());

export const setDesignWalletPlatform = createAction(
  "@app/set-design-wallet-platform",
  (payload: { wallet?: WalletPlatform } = {}) => payload,
);

export const setDesignWalletSideVisible = createAction(
  "@app/set-design-wallet-side-visible",
  (payload: { side?: WalletSideVisible } = {}) => payload,
);

export const setScheduledWalletPlatform = createAction(
  "@app/set-scheduled-wallet-platform",
  (payload: { wallet?: WalletPlatform } = {}) => payload,
);

export const setTriggeredBasedType = createAction("@app/set-triggered-based-type", (payload: { cardType?: CardType } = {}) => payload);

export const setTriggeredBasedWalletPlatform = createAction(
  "@app/set-triggered-based-wallet-platform",
  (payload: { wallet?: WalletPlatform } = {}) => payload,
);

export const setAnonymousWalletPlatform = createAction(
  "@app/set-anonymous-wallet-platform",
  (payload: { wallet?: WalletPlatform } = {}) => payload,
);

export const toggleSideNav = createAction("@app/toggle-side-nav", props<{ open?: boolean }>());

export const setSideNav = createAction("@app/set-side-nav", props<{ active: boolean; hasLogOut: boolean }>());

export const mobileSideNav = createAction("@app/mobile-side-nav", props<{ isMobile: boolean }>());

export const setAnalyticsFilters = createAction("@app/set-analytics-filters", props<AnalyticsFilters>());
