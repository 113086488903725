import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GenerateApiKeyData } from "./generate-api-key.interface";
import { UtilsService } from "../../services/utils/utils.service";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-generate-api-key",
  templateUrl: "./generate-api-key.component.html",
  styleUrls: ["./generate-api-key.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateApiKeyComponent implements OnInit {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenerateApiKeyData,
    public readonly dialogRef: MatDialogRef<GenerateApiKeyComponent, void>,
    public readonly utilsService: UtilsService,
  ) {}

  public ngOnInit(): void {
    this.form = new FormGroup({
      data: new FormControl<string>({ value: this.data.value, disabled: true }),
    });
  }
}
