import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UnderstoodComponent } from "./understood.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { InfoBannerComponentModule } from "../../components/info-banner/info-banner.module";

@NgModule({
  imports: [CommonModule, TranslateModule, MatButtonModule, MatDialogModule, InfoBannerComponentModule],
  declarations: [UnderstoodComponent],
  exports: [UnderstoodComponent],
})
export class UnderstoodComponentModule {}
