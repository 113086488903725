import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShopifyMultiPassComponent } from "./shopify-multi-pass.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { FormFieldWrapperComponentModule } from "../../components/form-field-wrapper/form-field-wrapper.module";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { InfoBannerComponentModule } from "../../components/info-banner/info-banner.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatLabel,
    MatFormFieldModule,
    FormFieldWrapperComponentModule,
    MatInputModule,
    MatButtonModule,
    InfoBannerComponentModule,
  ],
  declarations: [ShopifyMultiPassComponent],
  exports: [ShopifyMultiPassComponent],
})
export class ShopifyMultiPassComponentModule {}
