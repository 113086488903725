import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { RootState, fromProgram, fromRouter, programActions } from "./store";
import { AppRouteParams, AppRoutes } from "./enums";
import { Observable, filter, first, tap } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  public readonly loadingFullScreen$: Observable<boolean> = this.store.select(fromProgram.selectLoadingFullScreen);

  constructor(private readonly store: Store<RootState>) {}

  public ngOnInit(): void {
    this.store
      .select(fromRouter.selectRouteParam(AppRouteParams.programSlug))
      .pipe(
        first(programSlug => !!programSlug),
        filter(programSlug => programSlug !== AppRoutes.notFound),
        tap(slug => this.store.dispatch(programActions.getProgram({ slug, loadingFullScreen: true }))),
      )
      .subscribe();

    this.store
      .select(fromProgram.selectProgram)
      .pipe(
        first(program => !!program),
        tap(program => {
          this.store.dispatch(programActions.getProgramSubscriptionInfo({ params: { programId: program?.id }, redirect: true }));
        }),
      )
      .subscribe();
  }
}
