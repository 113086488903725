/**
 * JeriCommerce Pass API · staging
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0.0.1238
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CampaignProperties } from './campaign-properties';


export interface CreateCampaign { 
    name: string;
    slug?: string;
    active: boolean;
    type: CreateCampaign.TypeEnum;
    properties: CampaignProperties;
}
export namespace CreateCampaign {
    export type TypeEnum = '0' | '1' | '2' | '3' | '4';
    export const TypeEnum = {
        _0: '0' as TypeEnum,
        _1: '1' as TypeEnum,
        _2: '2' as TypeEnum,
        _3: '3' as TypeEnum,
        _4: '4' as TypeEnum
    };
}


