import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UpdateBalanceComponent } from "./update-balance.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { BalanceCardComponentModule } from "../../components/balance-card/balance-card.module";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { FormFieldWrapperComponentModule } from "../../components/form-field-wrapper/form-field-wrapper.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { PositiveNumbersDirectiveModule } from "../../directives/positive-numbers/positive-numbers.module";
import { InfoBannerComponentModule } from "../../components/info-banner/info-banner.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    BalanceCardComponentModule,
    MatButtonToggleModule,
    FormFieldWrapperComponentModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    PositiveNumbersDirectiveModule,
    InfoBannerComponentModule,
  ],
  declarations: [UpdateBalanceComponent],
  exports: [UpdateBalanceComponent],
})
export class UpdateBalanceComponentModule {}
