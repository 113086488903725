import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CouponSelectorComponent } from "./coupon-selector.component";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormFieldWrapperComponentModule } from "../../form-field-wrapper/form-field-wrapper.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { HtmlToTextModule } from "../../../pipes/html-to-text/html-to-text.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormFieldWrapperComponentModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatListModule,
    HtmlToTextModule,
  ],
  declarations: [CouponSelectorComponent],
  exports: [CouponSelectorComponent],
})
export class CouponSelectorComponentModule {}
